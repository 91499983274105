.whiz-container {
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  position: relative;
  z-index: 2147483647;
  pointer-events: auto;

  .connection-status-bar {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    
    .connection-indicator {
      padding: 4px 12px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      cursor: help;
      
      &.connected {
        background-color: #e6f7e6;
        color: #2e7d32;
      }
      
      &.disconnected {
        background-color: #ffebee;
        color: #c62828;
      }
      
      &.initializing {
        background-color: #fff8e1;
        color: #f57f17;
      }
      
      &.failed {
        background-color: #ffebee;
        color: #c62828;
        font-weight: bold;
      }
    }
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 70px;
    position: relative;
  }

  .message {
    margin-bottom: 16px;
    display: flex;
    position: relative;

    &.user {
      justify-content: flex-end;

      .message-content {
        background: #007bff;
        color: white;

        pre, code {
          background: rgba(255, 255, 255, 0.1);
          color: white;
        }

        a {
          color: white;
          text-decoration: underline;
        }

        // Override syntax highlighter background for user messages
        div[class*="language-"] {
          background: transparent !important;
          padding: 0 !important;
          margin: 0.5em 0 !important;
          border-radius: 4px;
          
          code {
            background: rgba(255, 255, 255, 0.1) !important;
            color: white !important;
          }
        }
      }
    }

    &.assistant {
      justify-content: flex-start;

      .message-content {
        background: white;
        color: #333;

        pre, code {
          background: #f8f9fa;
          color: #333;
        }

        a {
          color: #007bff;
        }

        // Style syntax highlighter for assistant messages
        div[class*="language-"] {
          background: #f8f9fa !important;
          padding: 0.75em !important;
          margin: 0.5em 0 !important;
          border-radius: 4px;
          
          code {
            background: transparent !important;
            color: inherit !important;
          }
        }
      }
    }

    .message-content {
      max-width: 70%;
      padding: 12px 16px;
      border-radius: 12px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      position: relative;

      // Markdown styles
      p {
        margin: 0 0 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }

      h1, h2, h3, h4, h5, h6 {
        margin: 1em 0 0.5em;
        &:first-child {
          margin-top: 0;
        }
      }

      ul, ol {
        margin: 0.5em 0;
        padding-left: 1.5em;
      }

      li {
        margin: 0.25em 0;
      }

      pre {
        margin: 0.5em 0;
        padding: 0.75em;
        border-radius: 6px;
        overflow-x: auto;
      }

      code {
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-family: monospace;
      }

      blockquote {
        margin: 0.5em 0;
        padding-left: 1em;
        border-left: 3px solid #ddd;
        color: #666;
      }

      table {
        border-collapse: collapse;
        margin: 0.5em 0;
        width: 100%;

        th, td {
          border: 1px solid #ddd;
          padding: 0.5em;
          text-align: left;
        }

        th {
          background: #f8f9fa;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        margin: 0.5em 0;
      }

      // Override syntax highlighter styles
      div[class*="language-"] {
        margin: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        
        pre {
          margin: 0 !important;
          padding: 0 !important;
          background: transparent !important;
        }
        
        code {
          font-family: 'Fira Code', monospace !important;
          font-size: 0.9em !important;
          line-height: 1.5 !important;
        }
      }
    }

    .typing {
      span {
        display: inline-block;
        margin: 0 2px;
        animation: typing 1s infinite;

        &:nth-child(2) { animation-delay: 0.2s; }
        &:nth-child(3) { animation-delay: 0.4s; }
      }
    }
  }

  .message-form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 12px;
    padding: 16px;
    background: white;
    border-top: 1px solid #ddd;
    z-index: 2147483647;

    input {
      flex: 1;
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      font-size: 14px;
      position: relative;

      &:focus {
        outline: none;
        border-color: #007bff;
      }

      &:disabled {
        background: #f5f5f5;
      }
    }

    button {
      padding: 12px 24px;
      background: #007bff;
      color: white;
      border: none;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      position: relative;

      &:hover:not(:disabled) {
        background: #0056b3;
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 12px 16px;
    margin: 8px;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &::before {
      content: "⚠️";
      margin-right: 8px;
    }
    
    .retry-button {
      margin-left: 12px;
      padding: 4px 8px;
      background-color: #c62828;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
      
      &:hover {
        background-color: #b71c1c;
      }
    }
  }
  
  .loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 4px solid #f3f3f3;
      border-top: 4px solid #007bff;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 16px;
    }
    
    p {
      color: #666;
      font-size: 16px;
    }
  }

  .debug-info {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    color: #666;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@keyframes typing {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mentioned-people {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.person-link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: #e8f4ff;
  border: 1px solid #b3d4ff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: #0066cc;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    color: #0066cc;
    flex-shrink: 0;
  }

  span {
    // Prevent long names from breaking layout
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: #d1e9ff;
    border-color: #80bdff;
  }
}

.user-context {
  margin-top: 8px;
  padding: 8px;
  background-color: #e8f4ff;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.mentioned-data {
  margin-top: 8px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  white-space: pre-wrap;
  word-wrap: break-word;
} 